<script setup lang="ts"></script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_335_1415)">
            <path
                d="M21.3946 16.9131C19.8827 15.635 19.0157 13.767 19.0157 11.788V9C19.0157 5.48108 16.4017 2.56805 13.0157 2.08008V0.999939C13.0157 0.44696 12.5676 0 12.0156 0C11.4637 0 11.0156 0.44696 11.0156 0.999939V2.08008C7.62854 2.56805 5.01562 5.48108 5.01562 9V11.788C5.01562 13.767 4.14862 15.635 2.62756 16.921C2.23865 17.254 2.01562 17.738 2.01562 18.2499C2.01562 19.2151 2.8006 20.0001 3.76556 20.0001H20.2656C21.2307 20.0001 22.0157 19.2151 22.0157 18.2499C22.0157 17.738 21.7927 17.254 21.3946 16.9131Z"
                fill="url(#paint0_linear_335_1415)"
            />
            <path
                d="M12.0156 24C13.8267 24 15.3417 22.7089 15.6896 21H8.34155C8.68964 22.7089 10.2047 24 12.0156 24Z"
                fill="url(#paint1_linear_335_1415)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_335_1415"
                x1="12.0157"
                y1="0"
                x2="12.0157"
                y2="24"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#93A4BC" />
                <stop offset="1" stop-color="#5A6273" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_335_1415"
                x1="12.0157"
                y1="0"
                x2="12.0157"
                y2="24"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#93A4BC" />
                <stop offset="1" stop-color="#5A6273" />
            </linearGradient>
            <clipPath id="clip0_335_1415">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
